import React from "react";
// import Navbar from "../components/Navbar/Navbar";
import HomeTopSection from "../components/HomeTopSection/HomeTopSection";

const HomeScreen = () => {
    return(
        <div>
            {/* <Navbar/> */}
            <HomeTopSection/>
        </div>
    )
}

export default HomeScreen